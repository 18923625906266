{
  "name": "employee-portal",
  "version": "0.16.18",
  "scripts": {
    "start": "ng serve --hmr",
    "analyze": "source-map-explorer dist/*.*",
    "analyze:prod": "source-map-explorer dist_prod/*.*",
    "version-patch": "npm --no-git-tag-version version patch",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "deploy": "bash scripts/deploy.sh",
    "generate:api": "npx openapi --input http://localhost:9400/openapi.json --output ./src/app/modules/api --client angular --name ApiModule",
    "generate:forms": "npx ngx-openapi-form-generator -i http://localhost:9400/openapi.json -o src/app/forms/",
    "update": "npm run build && npm run deploy"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/flex-layout": "15.0.0-beta.42",
    "@angular/forms": "^18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@angular/service-worker": "^18.2.6",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@ngx-loading-bar/router": "^6.0.2",
    "chart.js": "^4.3.0",
    "dayjs": "^1.10.4",
    "marked": "^4.3.0",
    "ng2-charts": "^4.1.1",
    "ng2-pdf-viewer": "^9.1.5",
    "ngx-markdown": "^16.0.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.5.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular/build": "^18.2.6",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/language-service": "^18.2.6",
    "@types/marked": "^4.3.0",
    "@types/node": "^22.7.4",
    "minio": "^7.1.3",
    "ngx-openapi-form-generator": "^1.1.1",
    "source-map-explorer": "^2.5.0",
    "ts-node": "~10.9.1",
    "typescript": "^5.5.4"
  }
}
